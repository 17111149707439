import type { IDynamicBlocDefinition } from '../blocscatalog';

export const actionsCatalog: Record<string, IDynamicBlocDefinition> = {};

export const asyncActionsCatalog: Record<string, string> = {
};

export function registerEntityAction(name: string, actionDefinition: IDynamicBlocDefinition) {
    actionsCatalog[name] = actionDefinition;
}

export function registerAsyncEntityAction(name: string, moduleName: string) {
    asyncActionsCatalog[name] = moduleName;
}
