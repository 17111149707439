import * as React from 'react';
import * as assign from 'lodash/assign';
import type { States } from '@@services/services';
import {connectwith} from '@inwink/react-utils/decorators/connectwith';
import { translateBag } from '@inwink/i18n/utils/translate';
import { AppTextLabel } from '@@services/i18nservice';
import type { IEntityFilterProps } from "@@components/entities/definitions";
import { TimePicker } from './timepicker';

import './entityfilters.timeslothours.less';

export interface ITimeSlotHourFilterProps extends IEntityFilterProps {
    event?: States.IEventState;
    i18n?: States.i18nState;
}

@connectwith((state: States.IAppState) => {
    return {
        event: state.event,
        i18n: state.i18n,
    };
})
export class TimeSlotHourFilter extends React.Component<ITimeSlotHourFilterProps, any> {
    constructor(props: ITimeSlotHourFilterProps) {
        super(props);

        this.state = {
        };
    }

    _onTimeFromValidated = (value) => {
        const newfilter = assign({}, this.props.filters, {
            timeslottimefrom: value
        });

        if (this.props.filterChanged) this.props.filterChanged({ field: this.props.filterField, val: newfilter });
    };

    _onTimeToValidated = (value) => {
        const newfilter = assign({}, this.props.filters, {
            timeslottimeto: value
        });

        if (this.props.filterChanged) this.props.filterChanged({ field: this.props.filterField, val: newfilter });
    };

    render() {
        const label = translateBag(this.props.i18n, this.props.filterField.label);
        const nothingLabel = translateBag(this.props.i18n, this.props.filterField.defaultText);

        return <div className="filter timeslothours-filter">
            <label className="fieldlabel">{label}</label>
            <div className="timefilter">
                <AppTextLabel i18n="entityfilters.time.from" component="div" className="timesection time-from" />
                <TimePicker
                    eventid={this.props.event.eventid}
                    value={this.props.filters.timeslottimefrom}
                    minutesIncrement={5}
                    onChange={this._onTimeFromValidated}
                    nothingLabel={nothingLabel}
                />
            </div>
            <div className="timefilter">
                <AppTextLabel i18n="entityfilters.time.to" component="div" className="timesection time-to" />
                <TimePicker
                    eventid={this.props.event.eventid}
                    value={this.props.filters.timeslottimeto}
                    minutesIncrement={5}
                    onChange={this._onTimeToValidated}
                    nothingLabel={nothingLabel}
                />
            </div>
        </div>;
    }
}
