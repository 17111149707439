import * as React from 'react';
import * as moment from 'moment';
import { DatePicker } from '@inwink/datepicker';

import './timepicker.less';

export interface ITimePickerValue {
    hours: number;
    minutes: number;
}

export interface ITimePickerProps {
    eventid: string;
    value: ITimePickerValue;
    onChange?: (value: ITimePickerValue) => void;
    onValidated?: (value: ITimePickerValue) => void;
    hoursIncrement?: number;
    minutesIncrement?: number;
    nothingLabel?: string;
}

export class TimePicker extends React.Component<ITimePickerProps, any> {
    constructor(props: ITimePickerProps) {
        super(props);
        let current = null;
        if (props.value) {
            current = moment().hours(props.value.hours || 0).minutes(props.value.minutes || 0);
        }
        this.state = {
            currenttime: current
        };
    }

    timeChanged = (d: moment.Moment) => {
        // console.log("time changed", d);
        this.setState({ currenttime: d });

        if (this.props.onChange) {
            if (!d) {
                this.props.onChange(null);
            } else {
                const newVal = {
                    hours: d.hours(),
                    minutes: d.minutes()
                };
                this.props.onChange(newVal);
            }
        }
    };

    render() {
        return <div className="entityfilter-timepicker">
            <DatePicker
                className={"event-" + this.props.eventid}
                type="time"
                selected={this.state.currenttime}
                onChange={this.timeChanged}
                minutesStep={15}
            />
        </div>;
    }
}
