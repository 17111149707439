import * as React from 'react';
import {connectwith} from '@inwink/react-utils/decorators/connectwith';
import * as assign from 'lodash/assign';
import * as moment from 'moment';
import { translateBag } from '@inwink/i18n/utils/translate';
import { DisplayDate } from '@inwink/i18n/displaydate';
import { AppTextLabel } from '@@services/i18nservice';
import type { States } from '@@services/services';
import type { IEntityFilterProps } from "@@components/entities/definitions";

import './entityfilters.timeslotdays.less';

export interface ITimeSlotDaysFilterProps extends IEntityFilterProps {
    event?: States.IEventState;
    i18n?: States.i18nState;
}

@connectwith((state: States.IAppState) => {
    return {
        event: state.event,
        i18n: state.i18n,
    };
})
export class TimeSlotDaysFilter extends React.Component<ITimeSlotDaysFilterProps, any> {
    constructor(props:ITimeSlotDaysFilterProps) {
        super(props);
        this.state = {
            days: this.getDays(props)
        };
    }

    getDays(props:ITimeSlotDaysFilterProps) {
        let allsessions = props.event.data.sessions.data;
        const days = {};
        if (props.basePredicate) {
            allsessions = allsessions.filter(props.basePredicate);
        }

        allsessions.forEach((s) => {
            if (s.timeslots && s.timeslots.length) {
                const ts = s.timeslots[0];
                if (ts.startDate) {
                    const date = moment(ts.startDate).startOf('day').format("YYYY-MM-DD");
                    days[date] = true;
                }
            }
        });

        return Object.keys(days).sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;

            return 0;
        }).map((d) => moment(d));
    }

    filterChange = (arg) => {
        const value = (arg.target as HTMLSelectElement).value;
        const newfilter = assign({}, this.props.filters, {
            timeslotday: value
        });
        if (this.props.filterChanged) this.props.filterChanged({ field: this.props.filterField, val: newfilter });
    };

    renderSelect() {
        const allDaysLabel = translateBag(this.props.i18n, this.props.filterField.defaultText);
        const val = this.props.filters.timeslotday || "all";

        const options = [<option key="all" value="all">{allDaysLabel}</option>];
        if (this.state.days && this.state.days.length) {
            this.state.days.forEach((d) => {
                const day = d.format("YYYY-MM-DD");
                options.push(<option key={day} value={day}>{d.format('LL')}</option>);
            });
        }

        return <div className="filter timeslotdays-filter select">
            <AppTextLabel
                component="label"
                i18n={this.props.filterField.label[this.props.i18n.currentLanguageCode]}
                className="fieldlabel clickable"
            />
            <select className="bloc-lightborder" autoComplete="off" value={val} onChange={this.filterChange}>
                {options}
            </select>
        </div>;
    }

    selectDay = (day:moment.Moment) => {
        const dayCode = day.format("YYYY-MM-DD");
        const currentSelection = this.getSelection();
        const idx = currentSelection.indexOf(dayCode);
        if (idx >= 0) {
            currentSelection.splice(idx, 1);
        } else {
            currentSelection.push(dayCode);
        }

        const newfilter = assign({}, this.props.filters, {
            timeslotday: currentSelection
        });
        if (this.props.filterChanged) this.props.filterChanged({ field: this.props.filterField, val: newfilter });
    };

    getSelection() {
        let currentSelection = [];
        if (this.props.filters.timeslotday) {
            if (typeof this.props.filters.timeslotday === "string") {
                currentSelection = this.props.filters.timeslotday.split("+");
            } else if (Array.isArray(this.props.filters.timeslotday)) {
                currentSelection = this.props.filters.timeslotday;
            }
        }

        return currentSelection;
    }

    renderTags() {
        let days = null;
        const currentSelection = this.getSelection();

        if (this.state.days && this.state.days.length) {
            days = this.state.days.map((d) => {
                let content = null;
                const isSelected = currentSelection.indexOf(d.format("YYYY-MM-DD")) >= 0;
                if (this.props.filterField.display && this.props.filterField.display.format) {
                    content = this.props.filterField.display.format.map((f) => {
                        return <>
                            <DisplayDate
                                date={d}
                                component={f.component || "span"}
                                format={f.format}
                                className={f.className}
                            />
                        </>;
                    });
                } else {
                    content = <><DisplayDate className="dayitem" reject="YYYY" date={d} format="ll" /></>;
                }

                return <span
                    className={"filter-dayitem keywordbubble bloc-lightbg clickable"
                        + (isSelected ? " selected bloc-accentbg" : "")}
                    key={d.toString()}
                    onClick={() => this.selectDay(d)}
                >
                    {content}
                </span>;
            });
        }

        return <div className="filter timeslotdays-filter tags">
            <AppTextLabel
                component="label"
                i18n={this.props.filterField.label[this.props.i18n.currentLanguageCode]}
                className="fieldlabel clickable"
            />
            <div className="days-items">{days}</div>
        </div>;
    }

    render() {
        if (this.props.filterField && this.props.filterField.display) {
            if (this.props.filterField.display.type === "select") {
                this.renderSelect();
            }
        }
        return this.renderTags();
    }
}
